import React from "react";
import Helmet from "react-helmet";

import Layout from "src/components/layout";
import SEO from "src/components/seo";
import loading from "src/images/loading.gif";

const fetch = require('isomorphic-fetch')

class SearchResultsGlobal extends React.Component {
  constructor(props) {
    super(props);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.handleViewportChange = this.handleViewportChange.bind(this);
    this.closeAnnouncement = this.closeAnnouncement.bind(this);
    this.searchInput = React.createRef();
    this.handleDataCall = this.handleDataCall.bind(this);
    this.printResults = this.printResults.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.openFilterDropdown = this.openFilterDropdown.bind(this);
    this.dateSelect = this.dateSelect.bind(this);
    this.sortSelect = this.sortSelect.bind(this);
  }

  state = {
    pageInfo: [],
    localSearch: null,
    moreMultiplier: 1,
    allCategory: false,
    pagesCategory: false,
    newsCategory: false,
    documentsCategory: false,
    mediaCategory: false,
    mediumViewport: false,
    announcementClosed: false,
    dateDropdownOpen: false,
    dateSelected: "All",
    sortDropdownOpen: false,
    sortType: "Most Relevant"
  }

  componentDidMount() {
    const mediaQuery = window.matchMedia("(min-width: 1280px)");
    let allCategory = false;

    mediaQuery.addListener(this.handleViewportChange);

    this.handleViewportChange(mediaQuery);

    if (typeof window !== 'undefined' && window.innerWidth > 1280) {
      allCategory = true;
    }

    fetch(`${process.env.GATSBY_API_URL}/wp-json/wp/v2/pages?slug=search`)
      .then(response => response.json())
      .then(json => {
        if (json.length > 0) {
          this.setState({ pageInfo: json, allCategory });
        }
      })
  }


  handleDataCall(e) {
    e.preventDefault();
    const value = this.searchInput.current.value;

    if (window.document.getElementById("global-loading")) {
      window.document.getElementById("global-loading").classList.add("show-loading");
    }

    fetch(`${process.env.GATSBY_API_URL}/wp-json/relevanssi/v1/search?s=${value}`)
      .then(response => response.json())
      .then(json => {
        this.setState({
          localSearch: json.results ? json.results : [], dateSelected: "All", sortType: "Most Relevant"
        }, () => {
          window.document.getElementById("global-loading").classList.remove("show-loading");
        });
      })
  }

  handleCategoryClick(e) {
    const clickedSectionTitle = e.currentTarget.dataset.category;

    if (typeof window !== 'undefined' && window.innerWidth > 1280) {
      if (clickedSectionTitle === "all") {
        this.setState({ allCategory: true, pagesCategory: false, newsCategory: false, documentsCategory: false, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "pages") {
        this.setState({ allCategory: false, pagesCategory: true, newsCategory: false, documentsCategory: false, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "news") {
        this.setState({ allCategory: false, pagesCategory: false, newsCategory: true, documentsCategory: false, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "documents") {
        this.setState({ allCategory: false, pagesCategory: false,newsCategory: false, documentsCategory: true, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "media") {
        this.setState({ allCategory: false, pagesCategory: false, newsCategory: false, documentsCategory: false, mediaCategory: true, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      }
    } else {
      if (clickedSectionTitle === "all") {
        this.setState({ allCategory: !this.state.allCategory, pagesCategory: false, newsCategory: false, documentsCategory: false, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "pages") {
        this.setState({ allCategory: false, pagesCategory: !this.state.pagesCategory, newsCategory: false, documentsCategory: false, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "news") {
        this.setState({ allCategory: false, pagesCategory: false, newsCategory: !this.state.newsCategory, documentsCategory: false, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "documents") {
        this.setState({ allCategory: false, pagesCategory: false, newsCategory: false, documentsCategory: !this.state.documentsCategory, mediaCategory: false, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      } else if (clickedSectionTitle === "media") {
        this.setState({ allCategory: false, pagesCategory: false, newsCategory: false, documentsCategory: false, mediaCategory: !this.state.mediaCategory, dateDropdownOpen: false, moreMultiplier: 1, dateDropdownOpen: false, sortDropdownOpen: false });
      }
    }
  }

  handleViewportChange(e) {
    if (e.matches) {
      if (!this.state.allCategory && !this.state.pagesCategory && !this.state.newsCategory && !this.state.documentsCategory && !this.state.mediaCategory) {
        this.setState({ mediumViewport: true, allCategory: true });
      } else {
        this.setState({ mediumViewport: true });
      }
    } else {
      this.setState({ mediumViewport: false });
    }
  }

  closeAnnouncement() {
    this.setState({ announcementClosed: true });
  }

  printResults(results, section) {
    const multiplier = this.state.moreMultiplier;
    const amount = (multiplier === "max" || results[section].length < 10) ? results[section].length : 10 * multiplier;
    const items = [];

    for (let i = 0; i < amount; i++) {
      let link;

      if (results[section][i].category === "Events") {
        link = `/port-info/event-details/?e=${results[section][i].id}`
      } else if (results[section][i].category !== "Events" && results[section][i].url) {
        link = results[section][i].url;
      } else {
        link = null;
      }

      let item = link ?
        <div key={i} className="item content">
          <a href={link} rel="noopener noreferrer" target="_blank">
            <h4>
              {results[section][i].title.replace("&#038;", "&")}
              <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
            </h4>
          </a>

          <p>{results[section][i].excerpt}</p>

          <div>
            <p><span>Date Created:</span> {results[section][i].date}</p>
            <p><span>Date Modified:</span> {results[section][i].dateModified}</p>
          </div>
        </div>
        :
        <div key={i} className="item content">
          <h4>
            {results[section][i].title.replace("&#038;", "&")}
            <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
          </h4>

          <p>{results[section][i].excerpt}</p>

          <div>
            <p><span>Date Created:</span> {results[section][i].date}</p>
            <p><span>Date Modified:</span> {results[section][i].dateModified}</p>
          </div>
        </div>

      items.push(item);
    }

    let amountItem = (
      <div key={items.length} className="content amount">
        <p>Showing {items.length} of {results[section].length}</p>
      </div>
    )

    items.push(amountItem);

    return items;
  }

  loadMore(results, section) {
    const multiplier = this.state.moreMultiplier + 1;
    const amount = 10 * multiplier;

    if (amount >= results[section].length) {
      this.setState({ moreMultiplier: "max" });
    } else {
      this.setState({ moreMultiplier: this.state.moreMultiplier + 1 });
    }
  }

  openFilterDropdown(e) {
    const filterType = e.currentTarget.dataset.filtertype;

    if (filterType === "date") {
      this.setState({ dateDropdownOpen: !this.state.dateDropdownOpen, sortDropdownOpen: false });
    } else {
      this.setState({ sortDropdownOpen: !this.state.sortDropdownOpen, dateDropdownOpen: false });
    }
  }

  dateSelect(e) {
    const dateSelected = e.currentTarget.dataset.date;

    this.setState({ dateSelected });
  }

  sortSelect(e) {
    const sortType = e.currentTarget.dataset.sort;

    this.setState({ sortType });
  }


  render() {
    const pageData = this.state.pageInfo[0];
    let resultsArray = [];
    let startingInputValue = "";
    const occurrences = {
      "All Results": 0,
      "Pages": 0,
      "News":0,
      "Documents": 0,
      "Media": 0
    };
    const specificResults = {};
    let dateFilters = [];

    let results;

    // // check if search is coming from page or from header search
    // if (this.state.localSearch.length > 0) {
    //   results = this.state.localSearch;
    // } else {
    //   results = this.props.location.state ? this.props.location.state.searchResults : [];
    // }

    // check if search is coming from page or from header search
    if (this.state.localSearch !== null) {
      results = this.state.localSearch;
    } else {
      results = (this.props.location.state && this.props.location.state.searchResults) ? this.props.location.state.searchResults : [];
    }

    // remove duplicate items from results
    let uniqueIds = [];
    let filteredResults = results.filter(element => {
      let isDuplicate = uniqueIds.includes(element.id);

      if (!isDuplicate) {
        uniqueIds.push(element.id);

        return true;
      }

      return false;
    });

    resultsArray = filteredResults


    // set search bar placeholder text if coming from header search
    if (this.props.location.state) {
      startingInputValue = this.props.location.state.searchValue;
    }


    // determine years for date filter and filter results if date filter is applied
    let dateSelected = this.state.dateSelected;
    if (resultsArray) {
      let unorderedDateList = [];
      let cleanedResults = [];

      for (let i = 0; i < resultsArray.length; i++) {
        // add year to date filter list
        if (unorderedDateList.indexOf(resultsArray[i].date.slice(-4)) === -1) {
          unorderedDateList.push(resultsArray[i].date.slice(-4))
        }

        // filter results
        if (dateSelected === "All" || resultsArray[i].date.slice(-4) === dateSelected) {
          cleanedResults.push(resultsArray[i]);
        }
      }

      // set up date filters in order of most recent year
      dateFilters = unorderedDateList.sort((obj1, obj2) => {
        return parseInt(obj2) - parseInt(obj1);
      });

      resultsArray = cleanedResults;
    }

    // sort results based on sort by filter
    let appliedFilter = this.state.sortType;
    if (appliedFilter === "Oldest") {
      resultsArray = resultsArray.sort((obj1, obj2) => {
        return Number(Date.parse(obj1.date)) - Number(Date.parse(obj2.date));
      });
    } else if (appliedFilter === "Newest") {
      resultsArray = resultsArray.sort((obj1, obj2) => {
        return Number(Date.parse(obj2.date)) - Number(Date.parse(obj1.date));
      });
    }


    // categorize results
    if (resultsArray) {
      const allResultsArray = [];
      const pagesArray = [];
      const newsArray = [];
      const documentsArray = [];
      const mediaArray = [];

      for (let i = 0; i < resultsArray.length; i++) {
        // categorize results

        if (resultsArray[i].category === "Documents") {
          occurrences["Documents"] = (occurrences["Documents"] || 0) + 1;
          specificResults["Documents"] = documentsArray.push(resultsArray[i]);
        } else if (resultsArray[i].type === "post") {
          occurrences["News"] = (occurrences["News"] || 0) + 1;
          specificResults["News"] = newsArray.push(resultsArray[i]);
        } else if (resultsArray[i].type === "page" || (resultsArray[i].child && resultsArray[i].child.type === "page")) {
          occurrences["Pages"] = (occurrences["Pages"] || 0) + 1;
          specificResults["Pages"] = pagesArray.push(resultsArray[i]);
        }

        allResultsArray.push(resultsArray[i]);
      }

      occurrences["All Results"] = allResultsArray.length;

      specificResults["All Results"] = allResultsArray;
      // specificResults["Pages / News"] = pagesArray;
      specificResults["Pages"] = pagesArray;
      specificResults["News"] = newsArray;
      specificResults["Documents"] = documentsArray;
      specificResults["Media"] = mediaArray;
    }


    return (
      <React.Fragment>
        {
          pageData ?
            <Layout hasHero={false}>
              <SEO title="Search Results" description="" />
              <Helmet>
                <body className="no-hero" />
              </Helmet>

              <div className="search-results-container gutter">
                <div className="search-results">
                  {
                    !this.state.announcementClosed ?
                      <div className="announcement">
                        <button type="button" className="close-announcement" onClick={this.closeAnnouncement}><svg className="icon--close"><use xlinkHref="#icon-close" /></svg></button>

                        <h3 className="announcement__heading line">{pageData.acf.announcement_title}</h3>
                        <div className="announcement__inner" dangerouslySetInnerHTML={{ __html: pageData.acf.announcement_body }} />
                      </div>
                      :
                      null
                  }

                  <div className="search-input-container">
                    <form className="search-results__input" onSubmit={this.handleDataCall}>
                      <label htmlFor="search-input" className="visuallyhidden">Search the Port of Long Beach website:</label>
                      <input type="search" id="search-input" placeholder="Search" className="search-input" defaultValue={startingInputValue} ref={this.searchInput} />
                      <button type="submit" className="search-button" aria-label="submit search"><svg className="icon--search--results"><use xlinkHref="#icon-search" /></svg></button>
                      <div id="global-loading" className="loading-container"><img src={loading} alt="loading..." /></div>
                    </form>

                    <p className="search-results__number">{`${occurrences["All Results"]} Results Found`}</p>
                  </div>

                  <div className="search-results__categories">
                    <div className={`category ${this.state.allCategory ? "open" : ""}`}>
                      <div className="category__title content" onClick={this.handleCategoryClick} data-category="all">
                        <h2 className="heading heading--med line">{`All (${occurrences["All Results"]})`}</h2>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </div>

                      {
                        !this.state.mediumViewport && this.state.allCategory ?
                          <div className="category__results all">
                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                <div className="filters">
                                  <div className="filter">
                                    <p className="filter__type">Date Filter:</p>

                                    <div className={`filter__dropdown ${this.state.dateDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="date">
                                      <p className="current">{this.state.dateSelected}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li key={0} onClick={this.dateSelect} className={`${this.state.dateSelected === "All" ? "hide" : ""}`} data-date="All">All</li>

                                        {
                                          dateFilters.map((date, index) => {
                                            return (
                                              <li key={index + 1} onClick={this.dateSelect} className={`${this.state.dateSelected === date ? "hide" : ""}`} data-date={date}>{date}</li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="filter">
                                    <p className="filter__type">Sort By:</p>

                                    <div className={`filter__dropdown ${this.state.sortDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="sort">
                                      <p className="current">{this.state.sortType}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Most Relevant" ? "hide" : ""}`} data-sort="Most Relevant">Most Relevant</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Newest" ? "hide" : ""}`} data-sort="Newest">Newest</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Oldest" ? "hide" : ""}`} data-sort="Oldest">Oldest</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                :
                                null
                            }

                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                this.printResults(specificResults, "All Results")
                                :
                                <div className="item content">
                                  <p>No Results</p>
                                </div>
                            }

                            {
                              (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["All Results"].length > 10 ?
                                <div className="load-more">
                                  <button type="button" onClick={() => this.loadMore(specificResults, "All Results")}>Load More</button>
                                </div>
                                :
                                null
                            }
                          </div>
                          :
                          null
                      }
                    </div>

                    <div className={`category ${this.state.pagesCategory ? "open" : ""}`}>
                      <div className="category__title content" onClick={this.handleCategoryClick} data-category="pages">
                        <h2 className="heading heading--med line">{`Pages (${occurrences["Pages"]})`}</h2>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </div>

                      {
                        !this.state.mediumViewport && this.state.pagesCategory ?
                          <div className="category__results pages">
                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                <div className="filters">
                                  <div className="filter">
                                    <p className="filter__type">Date Filter:</p>

                                    <div className={`filter__dropdown ${this.state.dateDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="date">
                                      <p className="current">{this.state.dateSelected}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li key={0} onClick={this.dateSelect} className={`${this.state.dateSelected === "All" ? "hide" : ""}`} data-date="All">All</li>

                                        {
                                          dateFilters.map((date, index) => {
                                            return (
                                              <li key={index + 1} onClick={this.dateSelect} className={`${this.state.dateSelected === date ? "hide" : ""}`} data-date={date}>{date}</li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="filter">
                                    <p className="filter__type">Sort By:</p>

                                    <div className={`filter__dropdown ${this.state.sortDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="sort">
                                      <p className="current">{this.state.sortType}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Most Relevant" ? "hide" : ""}`} data-sort="Most Relevant">Most Relevant</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Newest" ? "hide" : ""}`} data-sort="Newest">Newest</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Oldest" ? "hide" : ""}`} data-sort="Oldest">Oldest</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                :
                                null
                            }

                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                this.printResults(specificResults, "Pages")
                                :
                                <div className="item content">
                                  <p>No Results</p>
                                </div>
                            }

                            {
                              (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["Pages"].length > 10 ?
                                <div className="load-more">
                                  <button type="button" onClick={() => this.loadMore(specificResults, "Pages")}>Load More</button>
                                </div>
                                :
                                null
                            }
                          </div>
                          :
                          null
                      }
                    </div>



                    <div className={`category ${this.state.newsCategory ? "open" : ""}`}>
                      <div className="category__title content" onClick={this.handleCategoryClick} data-category="news">
                        <h2 className="heading heading--med line">{`News (${occurrences["News"]})`}</h2>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </div>

                      {
                        !this.state.mediumViewport && this.state.newsCategory ?
                          <div className="category__results documents">
                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                <div className="filters">
                                  <div className="filter">
                                    <p className="filter__type">Date Filter:</p>

                                    <div className={`filter__dropdown ${this.state.dateDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="date">
                                      <p className="current">{this.state.dateSelected}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li key={0} onClick={this.dateSelect} className={`${this.state.dateSelected === "All" ? "hide" : ""}`} data-date="All">All</li>

                                        {
                                          dateFilters.map((date, index) => {
                                            return (
                                              <li key={index} onClick={this.dateSelect} className={`${this.state.dateSelected === date ? "hide" : ""}`} data-date={date}>{date}</li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="filter">
                                    <p className="filter__type">Sort By:</p>

                                    <div className={`filter__dropdown ${this.state.sortDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="sort">
                                      <p className="current">{this.state.sortType}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Most Relevant" ? "hide" : ""}`} data-sort="Most Relevant">Most Relevant</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Newest" ? "hide" : ""}`} data-sort="Newest">Newest</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Oldest" ? "hide" : ""}`} data-sort="Oldest">Oldest</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                :
                                null
                            }

                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                this.printResults(specificResults, "News")
                                :
                                <div className="item content">
                                  <p>No Results</p>
                                </div>
                            }

                            {
                              (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["News"].length > 10 ?
                                <div className="load-more">
                                  <button type="button" onClick={() => this.loadMore(specificResults, "News")}>Load More</button>
                                </div>
                                :
                                null
                            }
                          </div>
                          :
                          null
                      }
                    </div>



                    <div className={`category ${this.state.documentsCategory ? "open" : ""}`}>
                      <div className="category__title content" onClick={this.handleCategoryClick} data-category="documents">
                        <h2 className="heading heading--med line">{`Documents (${occurrences["Documents"]})`}</h2>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </div>

                      {
                        !this.state.mediumViewport && this.state.documentsCategory ?
                          <div className="category__results documents">
                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                <div className="filters">
                                  <div className="filter">
                                    <p className="filter__type">Date Filter:</p>

                                    <div className={`filter__dropdown ${this.state.dateDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="date">
                                      <p className="current">{this.state.dateSelected}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li key={0} onClick={this.dateSelect} className={`${this.state.dateSelected === "All" ? "hide" : ""}`} data-date="All">All</li>

                                        {
                                          dateFilters.map((date, index) => {
                                            return (
                                              <li key={index} onClick={this.dateSelect} className={`${this.state.dateSelected === date ? "hide" : ""}`} data-date={date}>{date}</li>
                                            )
                                          })
                                        }
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="filter">
                                    <p className="filter__type">Sort By:</p>

                                    <div className={`filter__dropdown ${this.state.sortDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="sort">
                                      <p className="current">{this.state.sortType}</p>
                                      <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                                      <ul className="dropdown-list">
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Most Relevant" ? "hide" : ""}`} data-sort="Most Relevant">Most Relevant</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Newest" ? "hide" : ""}`} data-sort="Newest">Newest</li>
                                        <li onClick={this.sortSelect} className={`${this.state.sortType === "Oldest" ? "hide" : ""}`} data-sort="Oldest">Oldest</li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                :
                                null
                            }

                            {
                              resultsArray !== undefined && resultsArray.length > 0 ?
                                this.printResults(specificResults, "Documents")
                                :
                                <div className="item content">
                                  <p>No Results</p>
                                </div>
                            }

                            {
                              (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["Documents"].length > 10 ?
                                <div className="load-more">
                                  <button type="button" onClick={() => this.loadMore(specificResults, "Documents")}>Load More</button>
                                </div>
                                :
                                null
                            }
                          </div>
                          :
                          null
                      }
                    </div>



                    {
                      this.state.mediumViewport ?
                        <div className="filters">
                          <div className="filter">
                            <p className="filter__type">Date Filter:</p>

                            <div className={`filter__dropdown ${this.state.dateDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="date">
                              <p className="current">{this.state.dateSelected}</p>
                              <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                              <ul className="dropdown-list">
                                <li key={0} onClick={this.dateSelect} className={`${this.state.dateSelected === "All" ? "hide" : ""}`} data-date="All">All</li>

                                {
                                  dateFilters.map((date, index) => {
                                    return (
                                      <li key={index} onClick={this.dateSelect} className={`${this.state.dateSelected === date ? "hide" : ""}`} data-date={date}>{date}</li>
                                    )
                                  })
                                }
                              </ul>
                            </div>
                          </div>

                          <div className="filter">
                            <p className="filter__type">Sort By:</p>

                            <div className={`filter__dropdown ${this.state.sortDropdownOpen ? "open" : ""}`} onClick={this.openFilterDropdown} data-filtertype="sort">
                              <p className="current">{this.state.sortType}</p>
                              <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>

                              <ul className="dropdown-list">
                                <li onClick={this.sortSelect} className={`${this.state.sortType === "Most Relevant" ? "hide" : ""}`} data-sort="Most Relevant">Most Relevant</li>
                                <li onClick={this.sortSelect} className={`${this.state.sortType === "Newest" ? "hide" : ""}`} data-sort="Newest">Newest</li>
                                <li onClick={this.sortSelect} className={`${this.state.sortType === "Oldest" ? "hide" : ""}`} data-sort="Oldest">Oldest</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        :
                        null
                    }
                  </div>

                  {
                    this.state.mediumViewport ?
                      <div className="full-width-content">
                        {
                          this.state.allCategory ?
                            <div className="category__results">
                              {
                                resultsArray !== undefined && resultsArray.length > 0 ?
                                  this.printResults(specificResults, "All Results")
                                  :
                                  <div className="item content">
                                    <p>No Results</p>
                                  </div>
                              }

                              {
                                (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["All Results"].length > 10 ?
                                  <div className="load-more">
                                    <button type="button" onClick={() => this.loadMore(specificResults, "All Results")}>Load More</button>
                                  </div>
                                  :
                                  null
                              }
                            </div>
                            :
                            null
                        }




                        {
                          this.state.pagesCategory ?
                            <div className="category__results">
                              {
                                resultsArray !== undefined && resultsArray.length > 0 ?
                                  this.printResults(specificResults, "Pages")
                                  :
                                  <div className="item content">
                                    <p>No Results</p>
                                  </div>
                              }

                              {
                                (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["Pages"].length > 10 ?
                                  <div className="load-more">
                                    <button type="button" onClick={() => this.loadMore(specificResults, "Pages")}>Load More</button>
                                  </div>
                                  :
                                  null
                              }
                            </div>
                            :
                            null
                        }



                        {
                          this.state.newsCategory ?
                            <div className="category__results">
                              {
                                resultsArray !== undefined && resultsArray.length > 0 ?
                                  this.printResults(specificResults, "News")
                                  :
                                  <div className="item content">
                                    <p>No Results</p>
                                  </div>
                              }

                              {
                                (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["News"].length > 10 ?
                                  <div className="load-more">
                                    <button type="button" onClick={() => this.loadMore(specificResults, "News")}>Load More</button>
                                  </div>
                                  :
                                  null
                              }
                            </div>
                            :
                            null
                        }

{
                          this.state.documentsCategory ?
                            <div className="category__results">
                              {
                                resultsArray !== undefined && resultsArray.length > 0 ?
                                  this.printResults(specificResults, "Documents")
                                  :
                                  <div className="item content">
                                    <p>No Results</p>
                                  </div>
                              }

                              {
                                (resultsArray !== undefined && resultsArray.length > 0) && this.state.moreMultiplier !== "max" && specificResults["Documents"].length > 10 ?
                                  <div className="load-more">
                                    <button type="button" onClick={() => this.loadMore(specificResults, "Documents")}>Load More</button>
                                  </div>
                                  :
                                  null
                              }
                            </div>
                            :
                            null
                        }
                      </div>
                      :
                      null
                  }
                </div>



                <div className={`popular-pages ${this.state.announcementClosed ? "announcement-closed" : ""}`}>
                  <h2 className="heading heading--med line">Most Popular Pages</h2>

                  <ul>
                    {
                      (pageData.acf.page_1_title && pageData.acf.page_1_link) &&
                      <li>
                        <a href={pageData.acf.page_1_link} target="_blank">{pageData.acf.page_1_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_2_title && pageData.acf.page_2_link) &&
                      <li>
                        <a href={pageData.acf.page_2_link} target="_blank">{pageData.acf.page_2_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_3_title && pageData.acf.page_3_link) &&
                      <li>
                        <a href={pageData.acf.page_3_link} target="_blank">{pageData.acf.page_3_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_4_title && pageData.acf.page_4_link) &&
                      <li>
                        <a href={pageData.acf.page_4_link} target="_blank">{pageData.acf.page_4_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_5_title && pageData.acf.page_5_link) &&
                      <li>
                        <a href={pageData.acf.page_5_link} target="_blank">{pageData.acf.page_5_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_6_title && pageData.acf.page_6_link) &&
                      <li>
                        <a href={pageData.acf.page_6_link} target="_blank">{pageData.acf.page_6_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_7_title && pageData.acf.page_7_link) &&
                      <li>
                        <a href={pageData.acf.page_7_link} target="_blank">{pageData.acf.page_7_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_8_title && pageData.acf.page_8_link) &&
                      <li>
                        <a href={pageData.acf.page_8_link} target="_blank">{pageData.acf.page_8_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_9_title && pageData.acf.page_9_link) &&
                      <li>
                        <a href={pageData.acf.page_9_link} target="_blank">{pageData.acf.page_9_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                    {
                      (pageData.acf.page_10_title && pageData.acf.page_10_link) &&
                      <li>
                        <a href={pageData.acf.page_10_link} target="_blank">{pageData.acf.page_10_title}</a>
                        <svg className="icon icon--arrow-rt"><use xlinkHref="#icon-arrow-rt" /></svg>
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </Layout>
            :
            null
        }
      </React.Fragment>
    )
  }
}

export default SearchResultsGlobal;

